import { withBase } from "ufo";

export function setUrlWithParams(url, params) {
  params = toGetParams(params);
  return `${url}${params ? "?" + params : ""}`;
}

export function toGetParams(body = {}) {
  const searchParams = new URLSearchParams();
  for (const key in body) {
    if (body[key]) searchParams.set(key, body[key]);
  }
  return searchParams.toString();
}

export function useFetchClient(baseURL = "") {
  const execute = async (url = "", options = {}) => {
    const defaultHeaders = {};

    if (!(options.body instanceof FormData)) {
      defaultHeaders["Content-Type"] = "application/json";
    }
    try {
      options = {
        ...options,
        headers: {
          ...defaultHeaders,
          ...options.headers
        }
      }

      const response = await fetch(url, options);
      const isContentTypeJSON = options.headers["Content-Type"] === "application/json";
      let data = null;

      if (response.status === 404) throw `Resource not found (404): ${url}`;
      
      if (isContentTypeJSON) {
        data = await response.json();
        data = data.response || data;
      } else data = await response.text();

      return {
        status: "success",
        data: data,
      };
    } catch(error) {
      console.log("[fetch]", error)
      return {
        status: "error",
        data: error,
      }
    }
  }

  return {
    /**
     * 
     * @param {string} url 
     * @param {object} body 
     * @param {{
     *  headers: object,
     *  credentials: "omit" | "include" | "same-origin"
     * }} options 
     */
    async get(url = "", body = {}, options = {}) {
      const params = toGetParams(body);
      let finalUrl = `${url}${params ? "?" + params : ""}`;

      options = {
        ...options,
        method: "GET",
      };

      if (baseURL) finalUrl = `${withBase(`${url}?${params}`, baseURL)}`;

      return await execute(finalUrl, options);
    },
    /**
     * 
     * @param {string} url 
     * @param {object} body 
     * @param {{
     *  headers: object,
     *  credentials: "omit" | "include" | "same-origin"
     * }} options 
     */
    async post(url = "", body = {}, options = {}) {
      let finalUrl = url;
      const shoudStringify = !(body instanceof FormData);

      options = {
        ...options,
        body: shoudStringify ? JSON.stringify(body) : body,
        method: "POST",
      };

      if (baseURL) finalUrl = `${withBase(url, baseURL)}${url === "/" ? "/" : ""}`;

      return await execute(finalUrl, options);
    }
  }
}

