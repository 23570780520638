import { useFetchClient } from "@/js/other/useFetchClient";
import { setCookie, getAllCookies } from "@other/cookies";

const client = useFetchClient();

setTimeout(() => {  
  handleBanners();
}, 10);

async function handleBanners() {
  try {
    const banner = await getBanner();

    if (!banner) return;

    mountBanner(banner);
    
    const isClosable = banner?.absolute == 1;

    const timeout = isClosable ? 0 : 20000;

    if (isClosable) {
      $("#popup_banner .popup_banner__cross").remove();
    }

    $("#popup_banner img").on("error", () => $("#popup_banner").remove());

    setTimeout(() => {
      $("#popup_banner > .modal").addClass("js-active");
    }, timeout);

    $("body").on("click", ".popup_banner__cross, .popup_banner__inner a img", (e) => {
      $("#popup_banner > .modal").removeClass("js-active")

      const expiresDate = new Date(Date.now() + +$("#popup_banner").attr("data-expires") * 1000);

      setCookie(`banner${banner.id}`, "0", {
        "expires": expiresDate,
      })

      if (isDev) {
        console.log("поставил баннерную куку в 0...")
        console.log("если не ставится, то нужно убрать на беке httpOnly или security :<")
      }
    });
  } catch(e) {
    console.log(`[error]: ${e}`)
  }
}

async function getBanner() {
  const keys = Object.keys(getAllCookies());
  const banners = (await client.get("/popup-banner/")).data;

  return banners?.data?.find((b) => {
    return keys.indexOf(`banner${b.id}`) === -1
  });
}

function mountBanner(data) {
  const template = `<div id="popup_banner"
        class="popup_banner__modal"
        data-expires="${data.period}"
      >
      <div class="popup_banner__modal modal">
          <div class="popup_banner__inner">
            <button class="popup_banner__cross flex items-center justify-center hover:opacity-60 is-active">
              <svg class="cursor-pointer stroke-grey-dark" width="18" height="18">
                <use xlink:href="/img/icons.svg?v=${SVG_VERSION}#close"></use>
              </svg>
            </button>
            ${ 
              data.url ?
              '<a href="' + data.url +'" rel="nofollow" style="cursor: pointer"><img class="popup_banner__img" src="' + data.pic + '" alt="' + data.alt + '"></a>' :
              '<img class="popup_banner__img" src="' + data.pic + '" alt="' + data.alt + '">'
            }
          </div>
      </div>
    </div>`;
  document.body.insertAdjacentHTML("beforeend", template);
}